
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/budgets_steps/create", payload);
    },
    update(id, payload) {
        return axios.put(`v1_genesis/v1/budgets_steps/update/${id}`, payload);
    },
    remove(id) {
        return axios.delete(`v1_genesis/v1/budgets_steps/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_genesis/v1/budgets_steps/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_genesis/v1/budgets_steps/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/budgets_steps/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/budgets_steps/`);
    }
}
