
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/budgets_steps_next/create", payload);
    },
    update( payload) {
        return axios.put(`v1_genesis/v1/budgets_steps_next/update/${payload.id_budgets_steps}/${payload.id_budgets}`, payload);
    },
    remove(id) {
        return axios.delete(`v1_genesis/v1/budgets_steps_next/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_genesis/v1/budgets_steps_next/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_genesis/v1/budgets_steps_next/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/budgets_steps_next/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/budgets_steps_next/`);
    }
}
