import { render, staticRenderFns } from "./budgets-steps-next.vue?vue&type=template&id=6d460bf4&scoped=true"
import script from "./budgets-steps-next.vue?vue&type=script&lang=js"
export * from "./budgets-steps-next.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d460bf4",
  null
  
)

export default component.exports